import { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import "../../../styles/general/glob_vid_overlay.css";

export default function GlobalVideoOverlay() {
  const [videoOpen, setVideoOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    function handleOpenVideoOverlay(e) {
      setVideoUrl(e.detail.url);
      setIsClosing(false);
      setVideoOpen(true);
      setIsPlaying(true);
    }

    window.addEventListener("open-global-video-overlay", handleOpenVideoOverlay);
    return () => {
      window.removeEventListener("open-global-video-overlay", handleOpenVideoOverlay);
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('error', (e) => {
        console.error('Video Error:', e);
        console.error('Video Error Details:', videoRef.current.error);
      });

      videoRef.current.addEventListener('loadeddata', () => {
        videoRef.current.play().catch(() => {
          setIsPlaying(false); // If autoplay fails, show play button
        });
      });

      videoRef.current.addEventListener('ended', () => {
        setIsPlaying(false);
      });
    }
  }, [videoOpen]);

  const togglePlay = (e) => {
    e.stopPropagation();
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleClose = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
    setIsClosing(true);
    setTimeout(() => {
      setVideoOpen(false);
      setVideoUrl("");
      setIsPlaying(false);
    }, 300);
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("video-overlay")) {
      handleClose();
    }
  };

  const overlayContent = videoOpen && (
    <div
      className={`video-overlay ${isClosing ? "closing" : ""}`}
      onClick={(e) => handleClose()}
    >
      <div 
        className={`video-container ${isClosing ? "slide-down" : "slide-up"}`}
      >
        <div 
          className="video-wrapper"
          onClick={togglePlay}
        >
          <video
            ref={videoRef}
            playsInline
            preload="auto"
          >
            <source 
              src={videoUrl} 
              type="video/mp4"
            />
            Sorry, your browser does not support embedded videos.
          </video>
          {!isPlaying && (
            <div className="play-overlay">
              <div className="play-button">▶</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return typeof document !== "undefined"
    ? ReactDOM.createPortal(overlayContent, document.body)
    : null;
}
